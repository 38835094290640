@import "src/components/scss/Common";

.payment-list {
  margin-bottom: -20px;
.remove{
    margin-top: 7px;
    button{
        font-size: 16px;
        background-color: transparent;
        border: none;
    }
}
  .field-group {
    margin-bottom: 13px;

    .container-title {
      margin-bottom: 21px;

      span {
        font-size: 16px;
        line-height: 22px;
        color: $colorBlackOpacity08;
      }
    }

    .field-total,
    .field-payment-type,
    .field-machine,
    .field-quantity-installments,
    .field-amount-installments {

      input {
        background-color: white;
        border-bottom: 1px solid $colorDarkGray;
        border-radius: 0;
        font-size: 22px;
        line-height: 32px;
        height: 38px;
        padding: 0;
        color: $colorBlackOpacity08;
      }

      .field-label {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        margin: 0 0 20px;
        color: $colorBlackOpacity08;
      }
    }

    .field-amount-installments {
      box-shadow: 0px 3px 6px $colorBlackBoxShadow;
      padding: 8px 11px 5px 11px;
      border-radius: 0px 12px 12px 0px;
      margin-top: -10px;

      input {
        border: 0;
      }
    }

    .field-quantity-installments {
      width: 65px;

      .input-container {
        display: flex;
        justify-content: center;

        input {
          width: 40px;
          text-align: center;
        }
      }
    }

    .field-payment-type,
    .field-machine {
      input {
        background-size: 18px 9px;
        background-position: right;
      }
    }

    .field-machine {
      max-width: 150px;
    }

    .mr-25 {
      margin-right: 25px;
    }
  }
}
