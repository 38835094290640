
.product_inventory_movement_content {
  span {
    display: block;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #000;
    margin-left: 18px;
    margin-bottom: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


.product_inventory_movement_table {  
  thead {
    height: 26px;
    border-radius: 8px;
    
    th {
      background-color: rgb(227, 162, 8);
      text-align: center;
      padding: unset;
      border-bottom: unset;
    }
  }

  tbody { 
    tr {
      pointer-events: none;
      height: 40px;
    }

    tr:first-child {
      height: 8px;
    }

    tr:nth-child(2) {
      background-color: #CBCBCB;
    }

    td {
      background-color: transparent;
      text-align: center;
      font-weight: 500;
      padding: unset;
      border-bottom: unset;
    }

    td:first-child {
      border-left: 1px solid #A7A7A7;
    }

    td:last-child {
      border-right: 1px solid #A7A7A7;
    }

    tr:last-child td {
      border-bottom: 1px solid #A7A7A7;
    }

    tr:last-child td:first-child {
      border-bottom: 1px solid #A7A7A7;
      border-bottom-left-radius:  8px;
    }

    tr:last-child td:last-child {
      border-bottom: 1px solid #A7A7A7;
      border-bottom-right-radius: 8px;
    }
  }
}