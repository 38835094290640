@import "../../components/scss/ErvaDoce.scss";

.purchase_suggestion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .RadioGroupRoot {
    display: flex;
    flex-direction: row;
    gap: 80px;
    width: calc(75%);
    padding: 0 8px;

    button {
      all: unset;
    }

    .RadioGroupItem {
      background-color: white;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: transparent;
      border: 2px solid black;
    }

    .RadioGroupItem:hover {
      background-color: #f7f7f7;
    }

    .RadioGroupIndicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .RadioGroupIndicator::after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #000;
    }

    .Label {
      all: unset;
      color: #000;
      font-size: 15px;
      line-height: 1;
      padding-left: 15px;
      align-items: baseline;
      align-self: center;
      display: flex;
      align-items: center;

      img {
        margin-right: 4px;
        justify-self: baseline;
      }
    }
  }

  .button_container {
    padding: 0 8px;

    button {
      width: 100%;
    }
  }
}

.purchase_suggestion_list {
  margin-top: 24px;
}

.tabs_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tabs_list {
  height: 46px;
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 6px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    height: auto;
    align-items: flex-start;
    gap: 0;
  }
}

.tabs_trigger {
  font-family: inherit;
  background-color: #70707096;
  padding: 9px 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  user-select: none;
  border: none;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  width: 25%;
  max-width: 252px;
  justify-content: center;
  height: 33px;
  box-shadow: 0px 3px 6px #00000029;

  @include media-breakpoint-down(sm) {
    width: 100%;
    max-width: none;
    border-radius: 0;
    box-shadow: none;

    &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
  }

  &.all {
    max-width: 140px;
  }

  label.custom-checkbox {
    display: flex;
    align-items: center;
    margin: 0;
    color: white;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    justify-content: center;
    position: relative;
    flex-grow: 1;

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      pointer-events: none;
    }

    .custom-checkbox-icon {
      width: 21px;
      height: 21px;
      border: 2px solid white;
      display: inline-block;
      vertical-align: middle;
      border-radius: 2px;
      position: relative;
      border-radius: 3px;
      background-color: transparent;
      display: flex;
      align-items: center;
      margin-right: 10px;
      position: absolute;
      left: 0;
    }

    span {
      text-align: center;
    }

    input[type="checkbox"]:checked+.custom-checkbox-icon {
      background-image: url('../../components/images/icon-checkbox.png');
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
    }
  }
}

.tabs_trigger[data-state='active'] {
  color: #fff;
  background-color: #707070;
  height: 36px;
  transition: height 250ms;
}

.tabs_content {
  flex-grow: 1;
  background-color: white;
  outline: none;
  border-radius: 12px 12px 0 0;
}

.generate_container {
  flex-direction: column;
  align-items: end;

  button {
    color: white;
  }
}

.check-value {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  img {
    margin-right: 10px;
  }
}

.supplier {
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    p {
        margin: 0;
        font-size: 16px;

        &:last-child {
            font-style: italic;
            font-size: 14px;
            margin-top: 6px;
        }
    }
  }
}

.checklist {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #77cfa8;
}

.gray {
  background-color: #F3F3F3;
}

.custom-checkbox {
  width: 21px;
  height: 21px;
  margin: 0;

  input {
    display: none;
    margin: 0;
  }
}

.custom-checkbox-icon img {
  display: none;
}

.custom-checkbox input:checked+.custom-checkbox-icon img {
  display: inline-block;
}

.custom-checkbox-icon {
  display: inline-block;
  width: 21px;
  height: 21px;
  border: 2px solid black;
  border-radius: 3px;
  background-color: white;
}

.custom-checkbox input:checked+.custom-checkbox-icon {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  border: none;
  padding: 0;
}
