@import "src/components/scss/Common";

.notification-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 32px;

    .notification-btn {
        position: relative;
        border: none;
        background-color: transparent;

        &::after {
            content: attr(data-count);
            width: 20px;
            height: 20px;
            font-size: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: calc(100% - 20px);
            color: #000;
            background-color: #E3A208;
            border-radius: 50%;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, .25);
        }
    }

    .notification-list {
        display: none;
        width: 400px;
        height: fit-content;
        min-height: 400px;
        border-radius: 6px;
        padding-bottom: 10px;
        position: absolute;
        top: calc(100% + 9px);
        left: -164px;
        z-index: 100;
        background-color: #FFF;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, .25);

        &.direction-left {
            left: -360px;
        }

        .btn-close {
            display: flex;
            justify-self: flex-end;
            border: none;
            background-color: transparent;
            font-size: 20px;
        }

        .notification-controls {
            padding: 5px 10px 15px;


            .btn-remove-all {
                border-radius: 4px;
            }

            hr {
                background-color: #A79C9C;
            }
        }


        .list {
            overflow-y: auto;
            max-height: 400px;
            list-style: none;

            > p {
                font-style: italic;
                text-align: center;
                padding: 8px 10px;
            }

            li {
                padding: 8px 10px;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                a {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    text-decoration: none;
                    font-style: normal;
                    width: 100%;
                    padding-right: 10px;

                    p {
                        color: #000;

                        &.title {
                            font-size: 20px;
                            font-weight: bold;
                        }

                        &.createdAt {
                            margin-top: 10px;
                            text-align: right;
                            width: 100%;
                        }
                    }
                }
            }

            &::-webkit-scrollbar-thumb {
                width: 5px;
                background-color: #000;
            }

            &::-webkit-scrollbar {
                width: 2px;
            }
        }

    }

    &.show {
        .notification-list {
            display: block;
        }

        .notification-btn {
            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                left: 7px;
                top: 100%;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #FFFFFF;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        position: initial;

        .notification-list {
            top: 0;
            width: initial;
            height: 100vh;
            max-height: initial;
            right: 0;
            left: 0;

            .list {
                max-height: calc(100vh - 100px);
            }
        }
    }
}
