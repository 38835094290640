.tooltip-container {
    p {
      margin: 0;
    }
  }

  .tooltip {
    position: relative;
    display: flex;
    align-items: center;
    text-align: left;
    width: fit-content;

    &::after {
        content: 'i';
        position: relative;
        margin-left: 5px;
        background-color: black;
        color: white;
        border-radius: 100%;
        height: 18px;
        width: 18px;
        padding: 2px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        box-sizing: border-box;
        font-size: 14px;
        flex-shrink: 0;
    }
  }

  .orange-text {
    color: $colorOrange;
  }

  .tooltip .tooltip-text {
    &::before {
        content: 'i';
        position: relative;
        margin-right: 5px;
        background-color: black;
        color: white;
        border-radius: 100%;
        height: 18px;
        width: 18px;
        padding: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        box-sizing: border-box;
        flex-shrink: 0;
    }

    min-width: 220px;
    height: fit-content;
    visibility: hidden;
    background-color: $colorOrange;
    color: $colorBlack;
    font-size: 14px;
    border-radius: 5px;
    padding: 5px;

    position: absolute;
    left: calc(100% - 23px);

    opacity: 0;
    transition: opacity 0.3s;

    display: inline-flex;
    align-items: center;
    text-align: left;
    font-weight: normal;
    z-index: 1;
  }

  .tooltip-text .tooltip {
    margin-right: 5px;
  }

  .tooltip .tooltip-text::after {
    position: absolute;
    width: 200px;
    height: 50px;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
