@import "src/components/scss/Common";

.purchase-detail {
  .back-button {
    margin: 0;
  }

  .button-container {
    button {
      margin-bottom: 29px;
    }
  }

  .check-button {
    height: 30px;
  }

  .table-container {
    table {
      thead {

        th {
          height: 62px;
          text-align: center;

          &:first-child {
            text-align: left;
          }
        }
      }

      tbody tr td {

        &:first-child {
          text-align: left;
        }

        input {
          border: 2px solid $colorBlue;
          border-radius: 12px;
          text-align: center;
          height: 30px;
          width: 100%;
        }
      }

      .button-row {
        padding: 0 5px 0 0;

        button {
          display: inline-block;
        }
      }
    }
  }

  .update-purchase {
    button {
      color: $colorWhite;
    }
  }

  .pills-container {
    .text {
      margin: 0 65px;
    }
  }

  .info-container {
    p {
      font-style: italic;
    }
  }

  .count-selected {
    width: 70px;

    input {
        background-color: #E8E8E8;
        border: none !important;
    }
  }
}
