@import "src/components/scss/Common";

.field-permission-group {
  .title {
    display: block;
    border-radius: 12px;
    background-color: $colorGreen;
    text-align: center;
    font-weight: bold;
    padding: $space12;
  }

  .permission-container {
    display: table;
    border-collapse: separate;
    width: 100%;
    border-spacing: 0 $space6;

    .permission {
      display: table-row;
      background-color: $colorLightGray;

      > * {
        display: table-cell;
        padding: $space12;

        &:first-child {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }

        &:last-child {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }
}

.field-permissions {
    &:nth-child(n+5) {
        margin-top: 20px;
    }

    .title {
        display: block;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: $colorGreen;
        text-align: center;
        font-weight: bold;
        padding: $space12;
    }

    .options {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        display: flex;
        flex-wrap: wrap;
        background-color: $colorLightGray;

        label {
            padding: 8px 6px;
            margin: 0;
            text-align: center;
            font-size: 16px;
            color: #000;
        }
    }
}
