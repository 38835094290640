.product_inventory_movement_container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: stretch;
  justify-content: center;
  gap: 26px;
}


