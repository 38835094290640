.field-currency {
    position: relative;
    margin-bottom: 1rem;

    label {
        display: block;
        margin-bottom: 0.5rem;
    }

    input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .input-error {
        border-color: #B92020; 
    }

}