.switch {
  position: relative;
  display: inline-block;
  width: 30px;  /* 🔹 Aumentei a largura */
  height: 18px; /* 🔹 Diminuí a altura */
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px; /* 🔹 Diminuí o botão interno */
  width: 10px;  /* 🔹 Ajustei o tamanho */
  left: 3px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(14px); /* 🔹 Ajustei o deslocamento do botão */
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px; /* 🔹 Texto um pouco menor */
  font-weight: bold;
  color: #333;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

