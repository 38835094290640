@import 'src/components/scss/Common';

.product-screen-header {
  position: sticky;
  background-color: $colorWhite;
  z-index: 100;
  top: 0;
  width: 100%;
}

.container-form-error {
  border: 1px solid $colorRed;
}

.product-grid {
  display: grid;
  grid-template-columns: 20fr 85fr;
  grid-template-areas:
    'images infos'
    'images names'
    'barCodes names'
    'barCodes webNames'
  ;
  gap: 16px;
  padding: 0;
  margin-top: 16px;
}

.image-container {
  grid-area: images;
  background-color: $colorLightGray;
  border-radius: 8px;

  .image-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    label {
      margin: 0;
    }
  }

  .image-content {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 160px;
      height: 160px;
    }
  }
}

.barcodes-container {
  grid-area: barCodes;
  background-color: $colorLightGray;
  padding: 16px;
  border-radius: 8px;

  p {
    margin: 0;
  }

  .barcodes-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      margin: 0;
    }
  }

  .barcodes-content {
    margin-top: 16px;
    height: 120px;
    overflow: auto;
  }

  .barcodes-msg-error {
    margin-top: 4px;
    font-size: 14px;
    color: $colorRed;
  }
}

.product-infos-container {
  grid-area: infos;
  background-color: $colorLightGray;
//   width: 99.5%;
  padding: 16px;
  border-radius: 8px;

  .infos-content {
    display: flex;
    justify-content: space-around;

    .field-group {
      margin-bottom: 8px;
    }

    .suggestion-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        font-weight: bold;
        font-size: 14px;
        margin-left: 16px;
        margin-bottom: 10px;
      }

      .percentage-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .input-container {
          width: 92px;
        }
      }
    }
  }

  .relevant-infos-container {
    display: flex;
    justify-content: space-between;

    span {
      font-style: italic;
    }
  }
}

.names-container {
  grid-area: names;
  border-radius: 8px;
  background-color: $colorLightGray;
//   width: 99.5%;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .field-group {
    margin-bottom: 8px;
  }

  .result-name {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 28px;
    font-size: 14px;

    span {
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }
}

.web-names-container {
  grid-area: webNames;
  border-radius: 8px;
  background-color: $colorLightGray;
//   width: 99.5%;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .field-group {
    margin-bottom: 8px;
  }

  .result-name {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 28px;
    font-size: 14px;

    span {
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }
}

.fields-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px 0;

  .field-content {
    border-radius: 8px;

    .content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding: 0 8px 0 20px;

      label {
        margin: 0;
      }
    }

    background-color: $colorLightGray;
    width: 33%;
    padding: 16px;
  }
}

.fiscal-container {
    border-radius: 8px;
}

.description-modal {
  .container {
    width: 880px;

    textarea {
      border: 1px solid $colorDarkGray;
      border-radius: 0;
    }
  }
}

.gray-background {
  background-color: $colorLightGray;
  padding: 16px;
  flex-wrap: wrap;
}

.btn-create-container {
  .button-normal {
    width: 200px;
  }
}

.tag-icon {
  width: fit-content;

  label {
    display: flex;
    align-items: center;

    div {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-left: 10px;
    }
  }
}
