@import "../../components/scss/Common";

.transfer-modal {
  label {
    color: $colorTextSecondary;
    margin-left: 0;
    font-weight: normal;
  }

  input {
    text-align: center;
  }

  .product-info {
    width: 100%;
    background-color: #E8E8E8;
    padding: 20px 18px;
    border-radius: 12px;

    th, td {
        font-size: 18px;
        color: #000;
        background-color: transparent;
    }

    th {
        padding-bottom: 12px;
    }
  }
}
