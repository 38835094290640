.stock {
    .field-inputs {
        label {
            margin: 0 8px;

            &:first-child {
                margin: 0;
            }
        }

        input {
            margin-right: 5px;
        }
    }

    .border-left {
        border-left: 3px solid #EFEFEF;
    }
    .border-right {
        border-right: 3px solid #EFEFEF;
    }
    .red {
        color: #F54141;
    }
    .green {
        color: #378B0D;
    }

    .update-purchase {
        button {
            color: #FFF;
        }
    }

    .count-selected {
        width: 70px;

        input {
            border-radius: 12px;
            height: 30px;
            width: 100%;
            text-align: center;
            background-color: #E8E8E8;
            border: none !important;
        }
    }
}
